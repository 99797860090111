export const warningBuildingEcharts = (data,options = {})=>{
    let color2 = ['rgba(0,157,255, .3)', "rgba(89,255,255, .3)", "rgba(245,231,79, .3)", "rgba(255,56,107, .3)", "rgba(255,255,255, .3)"]
    let data2 = []
    data.forEach((item,index)=>{
        data2.push({
            value:item.value,
            name:item.name,
            itemStyle:{
                color:color2[index]
            }
        })
    })
    console.log(data2);
    return {
        tooltip: {
            trigger: 'item'
        },
        legend: {
            selectedMode:false,
            top: 'center',
            right:"5%",
            orient: 'vertical',
            itemWidth:5,
            itemHeight:5,
            itemGap:30,
            itemStyle:{
                borderColor: options.borderColor || '',
                borderWidth:0
            },
            textStyle:{
                fontSize:14,
                color: options.textColor || "#fff",
                padding:[0,0,0,10],
            }
        },
        series: [
            // 外层
            {
                name: '占比',
                type: 'pie',
                radius: ['60%', '68%'],
                avoidLabelOverlap: false,
                itemStyle: {
                borderColor: options.borderColor || '',
                borderWidth: 5
                },
                label: {
                    show: false,
                    position: 'center',
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: 18,
                        color: options.labelColor ||"#fff",
                        fontWeight: 'bold'
                    }
                },
                labelLine: {
                show: false
                },
                data: data
            },
            // 里层
            {
                name: '占比',
                type: 'pie',
                radius: ['60%', '42%'],
                avoidLabelOverlap: false,
                itemStyle: {
                // borderRadius: 10,
                borderColor: options.borderColor || '',
                borderWidth: 5
                },
                label: {
                    show: false,
                    position: 'center'
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: 24,
                        color:options.labelColor ||"#fff",
                        fontWeight: 'bold'
                    }
                },
                labelLine: {
                    show: false
                },
                data: data2
            },
            {
                name: '',
                type: 'pie',
                startAngle: 90,
                silent:true,
                radius: ['74%', '76%'],
                hoverAnimation: false,
                center: ['50%', '50%'],
                itemStyle: {
                    normal: {
                        labelLine: {
                            show: false
                        }
                    }
                },
                data: [{
                    value: 90,
                    itemStyle: {
                    normal: {
                        label: {
                            show: false
                        },
                        labelLine: {
                            show: false
                        },
                        color: options.outlineColor || "rgba(0, 31, 59,1)",
                        borderWidth: 0
                    },
                    }
                }]
            },
        ]
    }
}