<template>
  <div>
    <div>
      <div class="project-search flex container">
        <div class="container-left">
          <el-form :inline="true" :model="pageInfo" class="demo-form-inline" ref="searchForm" :label-width="90">
            <el-form-item label="事件类型：" prop="warningEventTypeId">
              <el-select v-model="pageInfo.warningEventTypeId" placeholder="请选择事件类型" size="large" clearable>
                <el-option label="全部" value=""/>
                <el-option v-for="(item,index) in eventTypeOptions" :label="item.name" :value="item.id" :key="index"/>
              </el-select>
            </el-form-item>
            <el-form-item label="预警时间：" prop="_times" style="width: 480px;">
              <div class="block">
                <el-date-picker clearable
                                @change="dateTimeRangeChange($event,'times')"
                                v-model="times"
                                type="datetimerange"
                                value-format="YYYY-MM-DD HH:mm:ss"
                                range-separator="至"
                                start-placeholder="开始时间"
                                end-placeholder="结束时间"
                                placeholder="请选择预警时间范围" size="large"/>
              </div>
            </el-form-item>
            <div style="padding-left: 80px;">
              <el-input v-model="pageInfo.search" placeholder="输入关键字查询" clearable size="large"
                        style="width: 200px"/>
            </div>
            <el-form-item label="消除方式：" prop="secureType">
              <el-select v-model="pageInfo.secureType" placeholder="请选择解除方式" size="large" clearable>
                <el-option label="全部" value=""/>
                <el-option v-for="(label, key) in secureTypeOptions" :key="label+key" :label="label" :value="key"/>
              </el-select>
            </el-form-item>
            <el-form-item label="消除时间：" prop="_secureTimes">
              <div class="block">
                <el-date-picker clearable
                                @change="dateTimeRangeChange($event,'secureTimes')"
                                v-model="secureTimes"
                                type="datetimerange"
                                value-format="YYYY-MM-DD HH:mm:ss"
                                range-separator="至"
                                start-placeholder="开始时间"
                                end-placeholder="结束时间"
                                placeholder="请选择消除时间范围" size="large"/>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div class="container-right flex">
          <button class="searchBtn" @click="search">搜索</button>
          <button class="resetBtn" @click="reset">重置</button>
          <button class="resetBtn" @click="showDataDialog">统计</button>
        </div>
      </div>
      <div class="project-table" style="margin-top: 20px">
        <el-table :data="tableData" border row-key="id"
                  :header-cell-style="{ 'text-align': 'center', 'color': '#999999', 'padding': '16px 0', 'background-color': '#EBEBEB' }"
                  :cell-style="{ 'text-align': 'center' }">
          <el-table-column label="序号" type="index" width="60"/>
          <el-table-column label="事件类型">
            <template #default="scope">{{ scope.row.warningEventType?.name || '' }}</template>
          </el-table-column>
          <el-table-column label="楼栋">
            <template #default="scope">{{ scope.row.building?.name || '' }}</template>
          </el-table-column>
          <el-table-column label="地点">
            <template #default="scope">{{ scope.row.laboratory?.name || '' }}</template>
          </el-table-column>
          <el-table-column prop="time" label="预警时间" width="170"/>
          <el-table-column label="状态">
            <template #default="scope">{{ stateOptions[String(scope.row.state)] }}</template>
          </el-table-column>
          <el-table-column prop="jie" label="解除方式">
            <template #default="scope">{{ secureTypeOptions[String(scope.row.secureType)] }}</template>
          </el-table-column>
          <el-table-column prop="secureTime" label="解除时间" width="170"/>
          <el-table-column label="操作" align="center">
            <template #default="scope">
              <span style="background:transparent; color:#3769B9;cursor: pointer;"
                    @click="Info(scope.row)">详情</span>
              <span style="background:transparent; color:#3769B9;cursor: pointer;"
                    class="marginLeft10"
                    @click="deleteRow(scope.row)">删除</span>
            </template>
          </el-table-column>
        </el-table>
        <div class="flex justify-center paddingTD20">
          <!--   分页     -->
          <el-pagination :currentPage="pageInfo.page" :page-size="pageInfo.limit" :page-sizes="[10, 20, 50, 100]"
                         :small="true" :disabled="false" :background="true"
                         layout="total, sizes, prev, pager, next, jumper"
                         :total="pageInfo.total" @size-change="handleSizeChange" @current-change="handleCurrentChange"/>
        </div>
      </div>
    </div>
    <!-- 操作窗口   -->
    <el-dialog v-model="dialogVisible" title="预警详情" width="50%" draggable @closed="closeDialog"
               style="padding: 15px;text-align: left">
      <div v-if="cur" class="text-center">
        <el-image :src="'api'+cur.imgPath" fit="contain" style="width: 100%;height: 400px"></el-image>
      </div>
      <template #footer>
        <div style="text-align: left;padding-bottom: 10px;" class="flex justify-between align-end">
          <div class="flex-1">
            <span style="padding-right: 40px">{{ cur?.warningEventType?.name }}</span>
            <span style="padding-right: 15px">{{ cur?.building?.name }}</span>
            <span>{{ cur?.laboratory?.name }}</span>
          </div>
          <div v-if="cur&&cur.state == 1" style="padding:0 15px;">{{ secureTypeOptions[String(cur.secureType)] }}</div>
          <div>{{ cur?.time }}</div>
        </div>
        <div class="flex-1 text-left marginBottom12">处理意见：{{ cur?.closeMsg }}</div>
        <div>
          <button type="button" class="subBtn" style="margin-right: 10px;" @click="handleVideo">联动录像</button>
          <button v-if="cur&&cur.state ==0" class="subBtn" type="button"
                  @click="showCloseDialog" style="margin-right: 10px;">解除预警
          </button>
          <button type="button" class="subBtn" @click="Download">下载</button>
          <button type="button" class="resetBtn" @click="closeDialog">关闭</button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="dialogVisible2" title="处理意见" width="30%" draggable style="padding: 24px;text-align: left">
      <template #default>
        <el-select v-model="closeForm.warningEventOpinionId" placeholder="请选择" size="large" style="width: 100%;" >
          <el-option v-for="item in warningProcessing" :key="item.id" :label="item.content" :value="item.id" />
        </el-select>
        <el-input v-show="selectedOther()" class="marginTop12px" type="textarea" v-model="closeForm.closeMsg" :rows="3" placeholder="请输入" maxlength="100"></el-input>
      </template>
      <template #footer>
        <button class="resetBtn" type="button" @click="dialogVisible2 = false">取消</button>
        <button class="searchBtn" type="button" @click="closeWarning">确认</button>
      </template>
    </el-dialog>
    <!--联动录像-->
    <el-dialog v-model="dialogHuifangVisible" title="联动录像" width="50%" draggable
               style="padding: 15px;text-align: left" @closed="closeVideoDialog">
      <div :id="playbackPlayer&&playbackPlayer.elementId" style="width: 100%;height:500px;"></div>
      <template #footer>
        <button class="resetBtn" type="button" @click.stop="closeVideoDialog">关闭</button>
      </template>
    </el-dialog>
    <!--统计弹框-->
    <classification-statistics v-if="dataDialog"
                               ref="dataStatistics"
                               :times="times"
                               @close="dataDialog = false"/>
  </div>
</template>

<script>
import {
  allWarningEventType,
  warningEventPage,
  getAllBuilding,
  warningEventClose,
  queryIndexAllDataView, monitorExistence, deleteWarningEvent, warningEventOpinionAll,
} from "@/api/monitor";
import {WARNING_EVENT_STATE, WARNING_EVENT_SECURE_TYPE} from "@/utils/config";
import ClassificationStatistics from "@/view/monitor/warnings/components/whiteStatistics.vue";
import dayjs from "dayjs";
import {PlaybackFlvPlayer} from "@/utils/monitor";

export default {
  name: "WarningEventList",
  components: {ClassificationStatistics},
  data() {
    return {
      dialogVisible: false,
      dialogVisible2: false,
      stateOptions: WARNING_EVENT_STATE,// 状态选项
      secureTypeOptions: WARNING_EVENT_SECURE_TYPE,// 解除方式选项
      eventTypeOptions: [], // 事件类型选项
      buildingOptions: [], // 楼栋选项
      tableData: [],
      // 时间范围
      times: [],
      secureTimes: [],
      pageInfo: {
        warningEventTypeId: '',
        secureType: '',
        startTime: '',
        endTime: '',
        secureStartTime: '',
        secureEndTime: '',
        search: '',
        limit: 10,
        page: 1,
        total: 0
      },
      // 当前事件
      cur: null,
      // 处理意见
      closeForm: {
        closeMsg: '',
        warningEventId: '',
        warningEventOpinionId: '',
      },
      // 统计
      dataDialog: false,
      //
      dialogHuifangVisible: false,
      // 回放摄像头
      playbackPlayer: null,
      //
      warningProcessing: [],
    }
  },
  mounted() {
    this.getAllEventType()
    this.getAllBuildings()
    this.getList();
    this.getWarningEventOpinionAll()
  },
  methods: {
    selectedOther(){
      const item = this.warningProcessing.find(iter => iter.id === this.closeForm.warningEventOpinionId)
      if (item) {
        return item.type === 1
      }
      return false
    },
    getWarningEventOpinionAll(){
      warningEventOpinionAll().then(res => {
        if (res.success) {
          this.warningProcessing = []
          Array.isArray(res.data) && res.data.forEach(iter => {
            const { content, type, id } = iter
            this.warningProcessing.push({ content, type, id  })
          })
        }
      })
    },
    // 显示统计弹框
    showDataDialog() {
      queryIndexAllDataView().then(res => {
        if (res.success) {
          this.allEventDetail = res.data || null
          this.dataDialog = true
          // this.$refs.dataStatistics.drawWarnings()
        } else {
          this.$message.error('获取统计数据失败')
        }
      })
    },
    //
    Info(item) {
      this.cur = item
      this.dialogVisible = true
    },
    /**
     * 删除
     * @constructor
     */
    deleteRow(item){
      this.$messageBox.confirm(
          '确认删除该预警事件吗',
          '提示',
          {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
          }
      ).then(() => {
        deleteWarningEvent(item.id).then(({success, message}) => {
          if (success) {
            this.$message.success('提交成功')
            this.getList()
          } else {
            this.$message.error(message)
          }
        })
      })
    },
    //联动录像 预警时间前后两分钟
    handleVideo() {
      if (!this.cur) return
      const equipmentId = this.cur.equipmentId
      let query = {
        startDate: dayjs(this.cur.time).add('-2', 'minutes').format('YYYY-MM-DD HH:mm:ss'),
        endTime: dayjs(this.cur.time).add('2', 'minutes').format('YYYY-MM-DD HH:mm:ss')
      }
      // 检测是否存在
      monitorExistence(equipmentId, query).then(res => {
        if (res.success) {
          this.dialogHuifangVisible = true
          if (this.playbackPlayer && this.playbackPlayer.flvPlayer) {
            this.playbackPlayer.destruction()
            this.playbackPlayer = null
          }
          this.playbackPlayer = new PlaybackFlvPlayer(equipmentId, 'playbackCamera', query.startDate, query.endTime)
          this.playbackPlayer.setShowScreenshot(false)
          this.$nextTick(() => this.playbackPlayer.play())
        } else {
          this.$message.error(res.message)
        }
      })
    },

    closeVideoDialog() {
      this.dialogHuifangVisible = false
      if (this.playbackPlayer && this.playbackPlayer.flvPlayer) {
        this.playbackPlayer.destruction()
        this.playbackPlayer = null
      }
    },
    showCloseDialog() {
      if (!this.cur) return;
      if (this.cur.state == 1) {
        this.$message.error('预警事件已解除')
        return;
      }
      this.closeForm = {
        warningEventId: this.cur.id,
        closeMsg: ''
      }
      this.dialogVisible2 = true
    },
    closeWarning() {
      if (!this.cur || this.cur.state == 1) return;
      warningEventClose(this.closeForm).then(res => {
        if (!res.success) return this.$message.error(res.message);
        this.cur.state = 1
        this.dialogVisible2 = false
        this.dialogVisible = false
        this.getList()
        this.$message.success('操作成功')
      })
    },
    // 下载
    Download() {
      if (!this.cur || !this.cur.imgPath) return
      const temps = this.cur.imgPath.split('/')
      const ext = temps[temps.length - 1].split('.').slice(-1)[0]
      const name = `${this.cur?.laboratory?.name || '预警事件'}.${ext}`
      fetch('api' + this.cur.imgPath)
          .then(res => res.blob())
          .then(blob => {
            const a = document.createElement('a')
            const objUrl = window.URL.createObjectURL(blob)
            a.download = name
            a.href = objUrl
            a.click()
          })
    },
    closeDialog() {
      this.dialogVisible = false
      this.cur = null
    },
    //
    getAllEventType() {
      allWarningEventType().then(res => {
        this.eventTypeOptions = res?.data || []
      }).catch(() => {
        this.eventTypeOptions = []
      })
    },
    getAllBuildings() {
      getAllBuilding().then(res => {
        this.buildingOptions = res?.data || []
      }).catch(() => {
        this.buildingOptions = []
      })
    },
    getList() {
      warningEventPage(this.pageInfo).then(res => {
        this.pageInfo.total = parseInt(res.data.total);
        this.tableData = res.data.records;
      })
    },
    // 搜索
    dateTimeRangeChange(e, key) {
      if (key === 'times') {
        this.pageInfo.startTime = e ? e[0] : ''
        this.pageInfo.endTime = e ? e[1] : ''
      }
      if (key === 'secureTimes') {
        this.pageInfo.secureStartTime = e ? e[0] : ''
        this.pageInfo.secureEndTime = e ? e[1] : ''
      }
    },
    reset() {
      this.times = []
      this.secureTimes = []
      this.pageInfo = Object.assign(this.pageInfo, {
        warningEventTypeId: '',
        startTime: '',
        endTime: '',
        secureStartTime: '',
        secureEndTime: '',
        secureType: '',
        search: '',
      })
    },
    search() {
      this.pageInfo.limit = 10;
      this.pageInfo.page = 1;
      this.getList();
    },
    // 分页
    handleSizeChange(e) {//修改每页显示条数
      this.pageInfo.limit = e;
      this.pageInfo.page = 1;
      this.getList();
    },
    handleCurrentChange(e) {
      this.pageInfo.page = e;
      this.getList();
    },
  }
}
</script>

<style lang="scss" scoped>
.demo-form-inline .el-input {
  --el-input-width: 220px;
}

.container {

  &-left {
    ::v-deep .el-form {
      display: flex;
      flex-wrap: wrap;
    }

    ::v-deep .el-form--inline .el-form-item {
      width: 28%;
    }
  }

  &-right {
    flex-shrink: 0;
  }
}
</style>